/* Add the overlay and content styles */
.overlay {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    56,
    63,
    82,
    0.6
  ); /* Semi-transparent background color */
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (min-width: 700px) {
    width: 60%;
  }
}

.content {
  max-width: 800px;
  padding: 20px;
  text-align: left;
}

.section1 h2 {
  font-size: 36px;
  margin-bottom: 20px;
}

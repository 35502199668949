body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
}

.App {
  text-align: center;
}

.section {
  /* height: 500px; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #fff;

  @media only screen and (min-width: 500px) {
    font-size: 16px; /* Adjust the font size for smaller screens */
  }

  @media only screen and (min-width: 1100px) {
    font-size: 20px; /* Adjust the font size for smaller screens */
  }
}

.section1 {
  min-height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  position: relative;
  background-color: #336bff; /* Fallback color in case the image doesn't load */
  background-size: cover;
  background-position: center;
  overflow: hidden; /* Hide any overflowing content, if necessary */

  @media only screen and (min-width: 500px) {
    min-height: 800px;
  }
}

.section2 {
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  position: relative;
  background-color: #33ffa8; /* Fallback color in case the image doesn't load */
  background-image: url("/src/assets/images/3a.jpg"); /* Use the new image for background */
  background-size: cover;
  background-position: center;
  overflow: hidden; /* Hide any overflowing content, if necessary */

  @media only screen and (min-width: 500px) {
    min-height: 800px;
  }
}

.section3 {
  height: 800px;
  color: #1f1f1f;
  font-weight: bold;
  padding: 5px;
  display: flex;
  flex-direction: column; /* Set the flex direction to row to create a two-column layout */
  align-items: center;
  background-image: url("./assets/images/4.jpg"); /* Set the background image */
  background-size: cover;
  background-position: center;

  @media only screen and (min-width: 500px) {
    padding: 50px;
    flex-direction: row;
    align-items: flex-start;
  }
}

.contactInfo {
  /* flex: 1; Take 50% of the available width (left column) */
  display: flex;
  flex-direction: column;
  align-items: center; /* Align items to the left */
  line-height: 1.5;
  max-width: 900px;
  margin-right: 20px; /* Add a margin to create space between the columns */
  color: rgb(84, 51, 18);

  @media only screen and (min-width: 500px) {
    align-items: flex-end;
  }
}

.googleMapsContainer {
  flex-grow: 1; /* Take 50% of the available width (right column) */
  max-width: 500px;
  height: 200px;
  border-radius: 50px;
}

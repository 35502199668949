.navbar {
  min-height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 2; /* Ensure the navbar is above the blurred overlay */

  @media only screen and (min-width: 500px) {
    min-height: 100px;
  }
}

.navbar a {
  color: #333;
  font-size: 10px;
  text-decoration: none;
  cursor: pointer;
}

.logoContainer {
  display: flex;
  align-items: center;
  margin-left: 20px;
  border-radius: 25px;
}

.logo {
  height: 50px; /* Set the height of the logo as needed */
  margin-right: 10px;
  border-radius: 5px;

  @media only screen and (min-width: 500px) {
    height: 90px; /* Adjust the font size for smaller screens */
  }

  animation: scaleAnimation 3s infinite alternate;
}

@keyframes scaleAnimation {
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1.1);
  }
}

.navText {
  text-decoration: none;
  color: #333;
  font-size: 15px;
  margin: 0 5px;
  cursor: pointer;
  white-space: nowrap;

  @media only screen and (min-width: 500px) {
    font-size: 20px;
  }
}

.active .navText {
  text-decoration: underline;
  font-weight: bold;
  color: rgb(180, 85, 85);
}

.navText:hover {
  color: rgb(157, 81, 81);
}
